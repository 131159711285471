<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <transition name="slide" v-on:after-leave="onClosed">
    <b-card v-show="state.isOpen" border-variant="light"
            class="text-center col-md-12  col-lg-6">
      <b-btn-close v-on:click="close()" type="button" aria-label="Close"></b-btn-close>
      <b-card-header class="text-center">
        <h4>{{ title }}</h4>
        <p> {{ subtitle }}</p>
        <hr>
      </b-card-header>
      <b-card-body class="text-left">
        <slot name="sidebar-content"></slot>
      </b-card-body>
    </b-card>
  </transition>
</template>

<script>
  export default {
    name: 'sidebar',
    props: ['title', 'subtitle'],
    data () {
      return {
        state: {
          isOpen: false
        }
      }
    },
    methods: {
      open () {
        this.$emit('openEvent')
        this.state.isOpen = true
      },
      close () {
        this.$emit('closeEvent')
        this.state.isOpen = false
      },
      onClosed () {
        this.$emit('closedEvent')
      },
      onKeyDown (e) {
        if (e.key === 'Escape') {
          this.close()
        }
      }
    },
    mounted () {
      window.addEventListener('keyup', this.onKeyDown)
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0px;
    height: 100vh;
    left: 0;
    z-index: 999;
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    border-radius: 0;

    .card-header {
      margin-top: 25px;
    }
  }
  .slide-leave, .slide-enter-to {
    transform: translate(0px);
  }
  .slide-enter, .slide-leave-to {
    transform: translate(calc(-100% - 3px));
  }
  .slide-enter-active, .slide-leave-active {
    transition: all 0.5s ease;
  }
</style>
